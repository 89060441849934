import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` we are big believers in voice interfaces. If you recall all the videos of two-year olds swiping iPhones and iPads, the same thing is happening with devices like Alexa and Google Home — kids already know how to interact with them. It’s the natural evolution of human computer interaction.`}</p>
    <p>{`2017 has turned out to be the year of voice. Amazon Alexa passed over 10 million unit sales earlier in the year and there are over 24,000 Skills in the store. With the addition of new devices like the Echo Show, Echo Plus, improved Echo Dot, and a new form factor for the Echo, there’s an option for everyone’s budget. Google is right there as well with the addition of the Google Mini to go along with the original Google Home. Apple’s efforts with Siri and HomePod, Samsung’s Bixby, and Microsoft’s Cortana round out the major tech firms efforts in this space.`}</p>
    <p>{`Brands and developers are right there alongside the major tech firms building applications for these devices. Brands across a variety of industries including Media, Entertainment, Retail, Travel, Banking, Local, CPG, and more have built voice applications for Alexa and Google Home.`}</p>
    <p>{`We recently conducted a survey, using Survata, amongst owners of Amazon Alexa and Google Home devices for their end-user experiences.`}</p>
    <p>{`Key takeaways from the survey include:`}</p>
    <ul>
      <li parentName="ul">{`Consumers are happy with the interactions and highly recommend the devices`}</li>
      <li parentName="ul">{`The devices are behavior changing — consumers use the devices frequently and often rely on them`}</li>
      <li parentName="ul">{`Consumers are pleasantly surprised by the ease of use, accuracy, and functionality of the devices`}</li>
      <li parentName="ul">{`There is an opportunity for generating awareness for 3rd party skills`}</li>
    </ul>
    <p>{`A key take away from the survey results is how often consumers use the device and how they see this as behavior changing.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171555/0_YEXoW6iCALCctPyQ.png)
    </figure>
Nearly 57% of participants use their device multiple times a day. Another 17.4%
use their device at least once a day, which makes over 74% using the device at
least once a day.
    <p>{`We asked respondents whether the device has changed their behavior or daily routines in anyway, and only 20% said “no” or weren’t sure. Diving deeper into their responses, many said how surprised they were by how useful and convenient the device is and how it has become part of their life — some even growing dependent on it.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171554/0_6wXHYP4Qzn_7hVFs.png)
    </figure>
Anecdotally, we often hear of how these devices have caused users behaviors to
change including listening to more music, using home automation, spending more
time in the room with the device, as well as subscribing to Amazon Prime because
of the device, among other behavior changes.
    <p>{`We asked users what features they use as well as how often they use them.`}</p>
    <p>{`The most common feature is listening to music, followed by checking the weather and asking for information. Surprisingly, home automation was one of the least common, as were getting sports scores, or playing games.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171553/0_DjGHZsiD9Inzv85D.png)
    </figure>
We dove deeper into the frequency of use of these features.
    <p>{`We found of respondents who listen to music, almost 63% listen multiple times throughout the day.`}</p>
    <p>{`For those who use their device to ask for information, more than 53% do so multiple times per day.`}</p>
    <p>{`While checking for weather is a common activity, it tends to be a little more of a daily activity — with 43% of respondents doing so, versus 38% checking multiple times a day.`}</p>
    <p>{`Interestingly enough, while overall only about 30% of respondents play games, the ones who do, play frequently — with about 56% playing multiple times a day.`}</p>
    <p>{`Comparing male versus female usage and frequency, there are some interesting differences. Slightly more men than women tend to use home automation features — about 30% of men versus 24% of women. About 46% of men use the device to get sports scores, whereas only 24% of women do. At the same time, 54% of men use the device for getting news, whereas only 44% of women do. Women tend to check the weather more than men — about 73% to 65%. Slightly more women tend to listen to music and ask for information over men as well — between 4–5% more.`}</p>
    <p>{`In addition to the features listed in the survey, through free form responses, common features mentioned were getting recipes, ordering food, shopping or reordering items, hearing jokes, and creating reminder lists.`}</p>
    <p>{`While consumers are using third party voice apps (e.g. “Alexa Skills”), they tend to use a smaller number of them. About 53% of consumers use one to three voice apps and an additional 23% use four to six. Only 14% of respondents said they do not use any third party skills.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171553/0_CcbHfsYfwnULn3d8.png)
    </figure>
Discovery is still a challenge.
    <p>{`The most common channels for consumers to find out about new skills are through social media, the device’s skill/app store, and friends.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171552/0_3FOWvR-aMynVqOzw.png)
    </figure>
When we ask brands how they acquire users for their voice skills, the most
common recommended channel is social media — either paid or organic. Media
brands that have multiple channels often cross promote their voice apps through
other affiliate channels as well.
    <p>{`For startups, we often hear social media influencer campaigns with videos work well. The video helps give users an idea of how to interact with the skill.`}</p>
    <p>{`The terminology for voice applications may need standardizing as well. Based on the free form responses to the survey, it’s not clear how many consumers know what a “Skill” is on Alexa or the equivalent on Google Home, especially as Google has changed from “Actions” to “Apps.” Voice Apps may be a clearer, more standardized term. One respondent in particular, when they mentioned specific Skills they used on Alexa, wrote that they did not even realize that “Skills” are what they are called.`}</p>
    <p>{`Users tend to be quite satisfied with both the device’s ability to understand users’ requests and its responses.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171551/0_-D4U7WXkuC3EyFUM.png)
    </figure>
Approximately 43% of users were very satisfied with their device’s ability to
understand their request. An additional 31% were somewhat satisfied, with only
about 13% unsatisfied or somewhat unsatisfied with the device’s ability to
understand them.
    <p>{`At the same time, nearly 47% of users were very satisfied with the response from their device. An additional 30% were somewhat satisfied, and only 14% were either unsatisfied or somewhat unsatisfied.`}</p>
    <p>{`Based on their overall experience with their devices, 51% of users were very satisfied with the device, and an additional 29% were somewhat satisfied. Only about 12% were either unsatisfied or somewhat unsatisfied.`}</p>
    <p>{`We asked respondents if anything about the devices surprised them, and the most common responses were:`}</p>
    <ul>
      <li parentName="ul">{`The ability of the device to understand their request`}</li>
      <li parentName="ul">{`The wide variety of functionality — how much the device can actually do`}</li>
      <li parentName="ul">{`The ease of use`}</li>
      <li parentName="ul">{`The speed and accuracy of responses`}</li>
      <li parentName="ul">{`How intelligent the devices are`}</li>
      <li parentName="ul">{`How convenient and useful the devices are`}</li>
      <li parentName="ul">{`The devices are even better than expected`}</li>
    </ul>
    <p>{`Other surprises included the devices sense of humor, how it gets smarter without having to update, the quality of the speaker, and how the device almost seems human, especially in its kind responses.`}</p>
    <p>{`Overall, respondents are very happy with their voice device. On a scale of one to five, respondents rated their device a 4.5 overall.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171550/0_0YAzgAo3_Uq6WAK1.png)
    </figure>
When asked if they would recommend their Alexa or Google Home to friends or
family, on a scale of 1 to 5, with five being “Very Likely,” the respondents
gave a 4.5 overall.
    <p>{`The results of these questions were about the same for both male and female users.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/11/06171550/0_fNyWZP9XLVPuP85K.png)
    </figure>
Consumers really love their Alexas and Google Homes. They’re pleasantly
surprised by the ease of use, all the functionality, and how they fit into their
daily routine.
    <p>{`There’s an opportunity to create more awareness for third party voice applications. With over 24,000 Skills in the Alexa store, users tend to use only a few of them and in some cases aren’t aware they exist or what they are.`}</p>
    <p>{`We’re excited about the future of voice and look forward to seeing the new features the platforms, brands, and developers create for these interfaces.`}</p>
    <h3>{`Methodology of Survey`}</h3>
    <p>{`The survey was commissioned by Dashbot and conducted by `}<a parentName="p" {...{
        "href": "http://www.survata.com/"
      }}>{`Survata`}</a>{`, an independent research firm in San Francisco. Survata interviewed 1010 online respondents between November 16, 2017 and November 17, 2017. For more information about the survey, please contact us at `}<a parentName="p" {...{
        "href": "mailto:survey@dashbot.io"
      }}>{`survey@dashbot.io`}</a>{`.`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      